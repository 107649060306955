.explore-H{
    color:#FFFFFF;
    font-size: 1.4;
}
.rem-H{
    font-size: 1.5rem;
    font-weight: 600;
    color: #FFFFFF;
}
.rem-P{
    font-size: 1rem;
    color: #000000;
    font-weight: 400;
    height: 15vh;
}
.b-Head{
    font-size: 2.2rem;
    color: #FFFFFF;
    font-weight: 600;
}
.b-Para{
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 400;
}
.b-LI{
    font-size: 1rem;
    color: #FFFFFF;
    font-weight: 600;
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
}
.b-LI::before {
    content: '\2022';
    color: #FFFFFF;
    margin-right: 8px;
    font-size: 1.5rem;
    margin-top: -5px;
}
.carousel-indicators{
    margin-bottom: 0px !important;
}
.carousel-control-next-icon {
    background-color: #FFFFFF; 
    width: 20px !important; 
    height: 20px !important; 
    border-radius: 50%;
    opacity: 0.8; 
}
  
  /* Optionally, you can also style the hover state */
.carousel-control-next-icon:hover {
    background-color: #FFFFFF; 
    opacity: 0.9; 
}
.carousel-control-prev-icon {
    background-color: #FFFFFF; 
    width: 20px !important; 
    height: 20px !important; 
    border-radius: 50%;
    opacity: 0.8; 
}
  
  /* Optionally, you can also style the hover state */
.carousel-control-prev-icon:hover {
    background-color: #FFFFFF; 
    opacity: 0.9; 
}
.carousel-control-next,.carousel-control-prev{
    top: 90px;
}

@media(min-width:768px){
    .carousel-control-prev-icon {
        background-color: #FFFFFF; 
        width: 40px !important; 
        height: 40px !important; 
        border-radius: 50%;
        opacity: 0.8; 
    }
      
      /* Optionally, you can also style the hover state */
    .carousel-control-prev-icon:hover {
        background-color: #FFFFFF; 
        opacity: 0.9; 
    }
    .carousel-control-next-icon {
        background-color: #FFFFFF; 
        width: 40px !important; 
        height: 40px !important; 
        border-radius: 50%;
        opacity: 0.8; 
    }
      
      /* Optionally, you can also style the hover state */
    .carousel-control-next-icon:hover {
        background-color: #FFFFFF; 
        opacity: 0.9; 
    }
   
}
.bell,.gift,.cake{
    color: #BA4F98;
}
.bell{
    animation: ring 2s  ease-in-out infinite;
}
@keyframes ring{
        0% { -webkit-transform: rotateZ(0); }
        1% { -webkit-transform: rotateZ(30deg); }
        3% { -webkit-transform: rotateZ(-28deg); }
        5% { -webkit-transform: rotateZ(34deg); }
        7% { -webkit-transform: rotateZ(-32deg); }
        9% { -webkit-transform: rotateZ(30deg); }
        11% { -webkit-transform: rotateZ(-28deg); }
        13% { -webkit-transform: rotateZ(26deg); }
        15% { -webkit-transform: rotateZ(-24deg); }
        17% { -webkit-transform: rotateZ(22deg); }
        19% { -webkit-transform: rotateZ(-20deg); }
        21% { -webkit-transform: rotateZ(18deg); }
        23% { -webkit-transform: rotateZ(-16deg); }
        25% { -webkit-transform: rotateZ(14deg); }
        27% { -webkit-transform: rotateZ(-12deg); }
        29% { -webkit-transform: rotateZ(10deg); }
        31% { -webkit-transform: rotateZ(-8deg); }
        33% { -webkit-transform: rotateZ(6deg); }
        35% { -webkit-transform: rotateZ(-4deg); }
        37% { -webkit-transform: rotateZ(2deg); }
        39% { -webkit-transform: rotateZ(-1deg); }
        41% { -webkit-transform: rotateZ(1deg); }
        43% { -webkit-transform: rotateZ(0); }
        100% { -webkit-transform: rotateZ(0); }
}
.gift{
    -webkit-animation: jump 1.5s ease 0s infinite normal ;
    animation: jump 1.5s ease 0s infinite normal ;
}
@keyframes jump {
    0%{
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    20%{
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    40%{
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
    }
    50%{
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    60%{
      -webkit-transform: translateY(-11px);
      transform: translateY(-11px);
    }
    80%{
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100%{
      -webkit-transform: translateY(0);
      transform: translateY(0);
   }
  }
  .cake{
    animation: bounceRotate 2s infinite;
  }
  @keyframes bounceRotate {
    0%, 100% {
        transform: translateY(0) rotate(0deg);
    }
    25% {
        transform: translateY(-10px) rotate(10deg);
    }
    50% {
        transform: translateY(0) rotate(0deg);
    }
    75% {
        transform: translateY(-10px) rotate(-10deg);
    }
}
.carousel-control-next-icon{
    background-image: url("../../public/assert/Homeimg/next.png") !important;
}
.carousel-control-prev-icon{
    background-image: url("../../public/assert/Homeimg/prev.png") !important;
}
.testimonial{
    background-color: #8FD4DB;
}
.card-slide{
    background-color: #fff !important;
    box-shadow: 0px 4px 4px 4px #00000040;
    height: 300px;
    border-radius: 10px !important;
}

  .react-multi-carousel-dot button{
    border-radius: 1% !important;
    width: 25px !important;
    border: none !important;
    height: 5px !important;
  }
  .react-multi-carousel-dot--active button{
    background: #BA4F98 !important;
  }
  .react-multi-carousel-dot-list{
    margin-top: 1rem !important;
  }
  .react-multiple-carousel__arrow--right{
    display: none !important;
  }
  .react-multiple-carousel__arrow--left{
    display: none !important;
  }


  
  .slider {
    margin:0 20px;
    overflow:"hidden";
    padding:2rem 0;
  }
  
  .slider img {
    width: 100%;
    border-radius:10px;
  }
  
  .react-multi-carousel-list {
  padding: 0rem 0 2rem 0;
  }
  
  .custom-dot-list-style button{
  border: none;
  background: #fff;
  border-radius: 10px !important;
  }
  .react-multi-carousel-dot.react-multi-carousel-dot--active button{
  background: #BA4F98 !important;
  width: 45px !important;
  }

  
