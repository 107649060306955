.F-para{
    font-size: 0.8rem;
    font-weight: 400;
    color: #4A4A4A;
}
/* .H-para{
    font-size: 2rem;
    font-weight: 600;
    color: ;
} */
.D-PARA{
    font-size: 1rem;
    font-weight: 400;
    color: #4A4A4A;
}
.social-icon{
    justify-content: start;
}
.f-logo{
    width: 80%;
    cursor: pointer;
}
.copy{
    text-align: center;
}
@media(min-width:768px){
    .social-icon{
        justify-content: end;
    }
    .f-logo{
        width: 100%;
        cursor: pointer;
    }
    .copy{
        display: flex;
        justify-content: space-between;
    }
}
.pink-border{
    width: 100%;
    height: 10px;
    background-color: #BA4F98;
}
.green-border{
    width: 100%;
    height: 10px;
    background-color: #78c7ce;
}
.foloowuson{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
}
.app-feature h3{
    font-size: 1.1rem;
    font-weight: 600;
}
.app-feature ul li{
    list-style-type: none;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 0px;
}
.app-feature ul li a{
    cursor: pointer;
    text-decoration: none;
    color: #4A4A4A;
}
.lg-center{
    display: flex;
    justify-content: center;
}
@media (max-width:992px){
    .lg-center{
        display: flex;
        justify-content: start !important;
    }
}