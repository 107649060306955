.send-B{
    font-size: 2.2rem;
    font-weight: 600;
}
.appimg{
    width: 40%;
}
@media (max-width:768px){
    .order-md-2{
        order: 2;
    }
    .order-md-1{
        order: 1;
    }
}
@media(min-width:768px){
    .appimg{
        width: 100%;
    }
}
.animated{
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
@keyframes up-down {
    0% {
      transform: translateY(10px);
    }
    
    100% {
      transform: translateY(-10px);
    }
}