.bg-body-tertiary{
    background-color: #BA4F98 !important;
}
.nav-link{
    color: white !important;
}
.what{
    align-items: center !important;
    padding-right: 1rem;
}
.dropdown-menu{
    background-color: #BA4F98;
    border: none;
    border-top: 0.3rem solid #78c7ce;
    border-bottom: 0.2rem solid #78c7ce;
    --bs-dropdown-link-active-bg:#78c7ce;
    box-shadow: 15px 15px 20px rgba(127, 137, 161, 0.25);
}
.drop{
    font-size: 0.8rem;
    color: white;
}
.drop:hover{
    background-color: #78c7ce;
    color: white;
    transition: 0.2s ease-in-out;
}
.navbar-toggler{
    border: none !important;
    color: transparent !important;
    transition: 0.2s ease-in-out;
}
/* .what:hover .dropdown-menu{
    display: block !important;
} */

#home:hover{
    color: #78c7ce !important;
}
#privacy:hover{
    color: #78c7ce !important;
}

.navbar-nav .nav-item.dropdown:hover .nav-link span {
    color: #78c7ce !important; 
}
.nav-link:hover{
    color: #78c7ce !important;
}
