.formVendor{
    background: #78c7ce;
    width: 90%;
    height: auto;
    border-radius: 5px;
}
.vendor-input{
    width: 100%;
    border: none;
    border-radius: 5px;
}
.vendor-input:focus{
    outline: none;
}
.vendor-formH{
    font-size: 1.8rem;
    font-weight: 400;
    text-align: center;
}
.vendor-formP{
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
}
.vendor-label{
    font-size: 1rem;
    font-weight: 500;
}
.checkbox-Vendor{
    font-size: 1rem;
    font-weight: 500;
    padding-left: 0.8rem;
}
.vendor-btn{
    background-color: #B94F98;
    border: none;
    color: #FFFFFF;
    font-size: 1.1rem;
    font-weight: 600;
    padding: 0.6rem 4rem;
    border-radius: 5px;
}
/* .dropdown-97{
    width: 100%;
    
}
.title-98 {
    border: none;
    border-radius: 5px;
} */
.css-1u9des2-indicatorSeparator{
    display: none;
}
.css-tj5bde-Svg{
    color: black;
}
.css-1p3m7a8-multiValue{
    border-radius: 1rem !important;
}
.css-t3ipsp-control{
    border: none !important;
    box-shadow: none !important;
}
.css-1fdsijx-ValueContainer{
    padding-left: 0.9rem !important;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked{
    color: #B94F98 !important;
}