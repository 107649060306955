.privacy-H{
    font-size: 1.7rem;
    font-weight: 700;
}
.info{
    font-size: 1.7rem;
    font-weight: 600;
    padding: 1rem 0;
}
.info-Per{
    font-size: 1.4rem;
    font-weight: 500;   
    padding-left: 1rem;
}

.li-Priv{
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px;
    padding-left: 2rem;
}
.li-Priv::before {
    content: '\2022';
    color: #000;
    margin-right: 8px;
    margin-top: 2px;
}
.info-Third{
    font-size: 1.3rem;
    font-weight: 500;
    /* padding-left: 1rem; */
    display: inline;
}
.li-Third{
    list-style-type: none;
    display: inline;
}
.privacy-choose{
    font-size: 1rem;
    font-weight: 400;
}